const getContentfulImgURL = ({
  url,
  width,
  height,
  quality,
  fit = "fill",
  focus = "center",
  format = "webp",
}) => {
  if (!url) {
    return ''
  }
  if (!(url.startsWith('http://') || url.startsWith('https://') || url.startsWith('//'))) {
    return url;
  }

  let imgUrl = url.includes('http')
    ? `${url}?fm=${format}`
    : `https:${url}?fm=${format}`;

  imgUrl = width ? imgUrl + `&w=${width}` : imgUrl;
  imgUrl = height ? imgUrl + `&h=${height}` : imgUrl;
  imgUrl = quality ? imgUrl + `&q=${quality}` : imgUrl;
  imgUrl = width || height ? imgUrl + `&fit=${fit}&f=${focus}` : imgUrl;

  return imgUrl;
};

const getPlaceholderURL = url => {
  let curUrl = url.replace("https:", "");
  curUrl = curUrl.replace("http:", "");
  if (curUrl.includes("?")) {
    const partials = curUrl.split("?");
    return `https:${partials[0]}?w=100&h=100&fm=webp&fit=fill&q=20`;
  }
  return `https:${curUrl}?w=100&h=100&fm=webp&fit=fill&q=20`;
};

const getImageSrcSetURL = ({
  url = "",
  scale = 1,
  sizes = [600, 800, 1600, 2000],
}) => {
  // const last = sizes.length - 1;
  return sizes
    .map(item => ({ width: item }))
    .map((item, index) => ({ ...item, url: getContentfulImgURL({ url: url, width: item.width / scale }) }))
    .map(item => `${item.url} ${item.width}w`)
    .join(",");
};

const CheckMobile = url => {
  if (process.browser) {
    if (window.innerWidth <= 800) {
      return true;
    } else {
      return false;
    }
  }
};

const setAutoWidth = url => {
  if (process.browser) {
    const width = window.innerWidth;

    if (checkBrowser()) {
      if (width < 600) {
        return url + "?w=" + 600;
      } else {
        return url + "?w=" + width;
      }
    } else {
      return url + "?w=" + width + "&fm=webp";
    }
  } else {
    return url;
  }
};

const setFixedWidth = (url, width) => {
  if (checkBrowser()) {
    return url + "?w=" + width;
  } else {
    return url + "?w=" + width + "&fm=webp";
  }
};

const setWidthDivision = (url, dividend) => {
  if (process.browser) {
    let width = window.innerWidth / dividend;
    width = Math.round(width);
    if (checkBrowser()) {
      return url + "?w=" + width;
    } else {
      return url + "?w=" + width + "&fm=webp";
    }
  } else {
    return url;
  }
};

const checkBrowser = () => {
  if (process.browser) {
    let isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
        (typeof safari !== "undefined" && safari.pushNotification)
      );

    let isInterEx = /*@cc_on!@*/ false || !!document.documentMode;
    let iPhoneiPad =
      window.navigator.userAgent.match(/iPad/i) ||
      window.navigator.userAgent.match(/iPhone/i);

    if (isSafari) {
      return true;
    } else if (iPhoneiPad) {
      return true;
    } else if (isInterEx) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export default {
  methods: {
    CheckMobile,
    checkBrowser,
    setAutoWidth,
    setFixedWidth,
    setWidthDivision,
    getPlaceholderURL,
    getImageSrcSetURL,
    getContentfulImgURL,
  }
};
