import Cookies from 'js-cookie'
import { isFilterMatched } from '@/helpers/route'
import TourListController from '~/api/TourListController'

var controller = new TourListController()
let anchorCartCheckTimer = null

const TourList = {
  state: {
    tags: [],
    cityTours: [],
    microData: {},
    cityData: {},
    filters: [],
    tour: {},
    initValues: {},
    compareTag: {},
    compareDetail: {},
    guideProfile: {},
    breadCrumbs: {},
    tourFilter: [],
    anchorCarts: 0,
    unavailabile: false,
  },
  actions: {
    async getTourUnavail (context, payload) {
      const data = await controller.getTourUnavailability(payload.tourUrl, payload.direct)
      context.commit('setUnavailabile', data)
      return data
    },
    getListContent: async (context, payload) => {
      try {
        const data = await controller.getListContent(payload.city)
        context.commit('setCityTours', data.tours)
        context.commit('setCityData', data.cityData)
        // context.commit('setCityFilters', data.filters)
      } catch (err) {
        console.error(`get city data err  -- ${payload.city}`, err)
        throw({statusCode: 404, message: 'City not found', err})
      }
    },
    async getToursAvailabilities (context, payload) {
      const data = await controller.getToursAvailabilities(payload.city)
      context.commit('updateAvailabilities', data)
    },
    getBreadCrumbs: (context, payload) => {
      const data = controller.buildBreadCrumbs(payload)
      context.commit('setBreadCrumbs', data)
    },
    async getTour (context, payload) {
      try {
        const data = await controller.getTour(this.$sentry, payload.city, payload.tour)
        context.commit('setTour', data)
        return data?.tour
      } catch(err) {
        console.error(`getTour req err: city: ${payload.city} tour: ${payload.tour}`, err)
        throw({statusCode: 404, message: 'Tour not found'})
      } 
    },
    setTourFilters: (context, payload) => {
      context.commit('setTourFilters', payload)
    },
    updateCart ({ commit, dispatch }) {
      const anchorId = Cookies.get('_anchorcid')
      const itemObjStr = Cookies.get('_anchorcidmeta')
      // console.log('update cart...')
      if (itemObjStr && anchorId) {
        const itemObj = JSON.parse(itemObjStr)
        commit('setAnchorCart', itemObj.totalItems)
        if (!anchorCartCheckTimer) {
          anchorCartCheckTimer = setInterval(() => dispatch('updateCart'), 5000)
        }
      } else {
        clearInterval(anchorCartCheckTimer)
        anchorCartCheckTimer = null
        commit('setAnchorCart', 0)
      }
    }
  },
  mutations: {
    setUnavailabile: (state, data) => {
      state.unavailabile = data
    },
    setTourFilters: (state, filters) => {
      state.tourFilter = filters
    },
    setInitialFilter: (state, qry) => {
      const { category } = qry
      let filters = qry.filters ?? []
      if (typeof filters === 'string') {
        filters = [filters]
      }
      if (!category) {
        state.tourFilter = filters 
        return
      }
      const matched = state.filters?.find(filter => isFilterMatched(filter, category))
      if (matched) {
        state.tourFilter = [matched]
      } else {
        state.tourFilter = filters
      }
    },
    setTags: (state, data) => {
      state.tags = data
    },
    setCityTours: (state, data) => {
      state.cityTours = data
    },
    setMicroData: (state, data) => {
      state.microData = data
    },
    setCityData: (state, data) => {
      state.cityData = data
    },
    setCityFilters: (state, data) => {
      state.filters = data
    },
    setBreadCrumbs: (state, data) => {
      state.breadCrumbs = data
    },
    setTour: (state, data) => {
      state.tour = data.tour
      state.initValues = {
        priceMap: data.tour.priceMap,
      }
    },
    setCompareTag: (state, data) => {
      state.compareTag = data
    },
    setAnchorCart: (state, data) => {
      state.anchorCarts = data
    },
    updateAvailabilities: (state, availabilities) => {
      // console.log('availabilities: ', availabilities)
      if (!availabilities || availabilities.length <= 0) {
        return
      }
      // console.log('availabilities -> ', availabilities)
      const tags = state.tags
      if (tags && tags.length > 0) {
        for (const tag of tags) {
          // console.log('tag -> ', tag.tours)
          for (const tour of tag.tours) {
            const bookingTypeId = tour.bookingTypeId.toString()
            if (availabilities.includes(bookingTypeId)) {
              tour.flag = 'Currently Unavailable'
            }
          }
        }
      }

      // for new city's tour list
      const cityTours = state.cityTours
      if (cityTours?.length > 0) {
        for (const cityTour of cityTours) {
          const bookingTypeId = cityTour.bookingTypeId.toString()
          if (availabilities.includes(bookingTypeId)) {
            cityTour.flag = 'Currently Unavailable'
          }
        }
      }
    },
    updateCityReviews: (state, reviewStatMap) => {
      // console.log('review to update => ', reviewStatMap)
      const tags = state.tags
      for (const tag of tags) {
        for (const tour of tag.tours) {
          // use bookingTypeId as uniqueKey for review
          const { bookingTypeId } = tour
          const reviewStat = reviewStatMap[bookingTypeId.toString()]
          if (reviewStat) {
            tour.reviewStatus = {
              feedbackAverage: reviewStat.average,
              feedbackCount: reviewStat.totalReviews,
            }
          }
        }
      }
    },
    updateTourReviewStats: (state, data) => {
      const { tourReviewStat, similarToursReviewStat} = data
      const tour = state.tour
      // console.log('tourReviewStat => ', tourReviewStat)
      // console.log('similarToursReviewStat => ', similarToursReviewStat)
      if (tourReviewStat) {
        tour.reviewsAverage = tourReviewStat.feedbackAverage
        tour.reviewsCount = tourReviewStat.feedbackCount
        tour.starsGroup = tourReviewStat.starsGroup
      }
      // similar tours
      const similarTours = tour.similarTours
      for (const similarTour of similarTours) {
        const { bookingTypeId } = similarTour
        const reviewStat = similarToursReviewStat[bookingTypeId.toString()]
        if (reviewStat) {
          similarTour.reviewStatus = {
            feedbackAverage: reviewStat.feedbackAverage,
            feedbackCount: reviewStat.feedbackCount,
          }
        }
      }
    }
  },
  getters: {
    tourfilter: state => {
      return state.tourFilter
    },
    tags: state => {
      return state.tags
    },
    filters: state => {
      return state.filters
    },
    microData: state => {
      return state.microData
    },
    cityData: state => {
      return state.cityData
    },
    breadCrumbs: state => {
      return state.breadCrumbs
    },
    tour: state => {
      return state.tour
    },
    compareTag: state => {
      return state.compareTag
    },
    compareDetail: state => {
      return state.compareDetail
    },
    guideProfile: state => {
      return state.guideProfile
    },
    initValues: state => {
      return state.initValues
    },
    anchorCarts: (state) => {
      return state.anchorCarts
    },
    cityTours: (state) => state.cityTours,
    unavailabile: state => state.unavailabile
  }
}

export default TourList
