<template>
  <div class="bg-container" id="nav-container">
    <div class="topnav container fixed-mobile" id="nav-target">
      <Logo />
      <!-- desktop -->
      <div class="topnav-nav">
        <div class="topnav-item nav-mobile nav-mobile-home">
          <nuxt-link to="/" class="link" @click.native="clickHome()">Home</nuxt-link>
        </div>
        <CityList
          class="dropdown-btn"
          continent="view our tours"
          :cities="cities"
          :franceCities="franceCities"
          :otherDestination="otherDestination"
        />
        <div class="menu">
          <div v-if="ribbonBanner" class="topnav-item item-offers">
            <nuxt-link v-if="ribbonBanner.link" :to="ribbonBanner.link" @click.native="clickMegaMenu('Offers', ribbonBanner.link)">
              <span class='text'>Offers</span>
            </nuxt-link>
          </div>
          <div v-if="!ribbonBanner || !ribbonBanner.hideMyBooking" class="topnav-item item-offers">
            <nuxt-link :to="'/booking-manage/'" @click.native="clickMegaMenu('my booking', '/booking-manage/')">
              <span class='text'>my booking</span>
            </nuxt-link>
          </div>
          <div class="topnav-item" v-for="(link, index) in menu" :key="index">
            <template v-if="link.name !== 'blog'">
              <router-link :to="link.router" @click.native="clickMegaMenu(link.name, link.router)">
                <span class='text'>{{link.name}}</span>
              </router-link>
            </template>
            <template v-else>
              <a :href="link.router" target="_blank" @click="clickMegaMenu(link.name, link.router)">
                <span class='text'>{{link.name}}</span>
              </a>
            </template>
          </div>
          <CurrencyList class="topnav-item" uiclass="red" />
          <HeaderCartButton class="topnav-item" />
        </div>
      </div>
      <!-- mobile -->
      <div class="mobile-menu">
        <CurrencyList class="topnav-item-mobile" uiclass="red" />
        <HeaderCartButton class="topnav-cart-mobile" v-show="isNotPaymentPage()"/>
        <div class="mobile-menu-btn" @click="openHamburger">
          <div>
            <svgicon name="hamburger" width="24" height="24" />
          </div>
        </div>
        <!-- mobile menu copy -->
        <div class="topnav-nav-small" :class="{openHamburger: mobileMenuShow}">
          <MobileMenu
            :offerLink="ribbonBanner? ribbonBanner.link: ''"
            :displayMyBooking="!ribbonBanner || !ribbonBanner.hideMyBooking"
            :menu="menu"
            :cities="mobileCities"
            :franceCities="mobileFranceCities"
            :otherDestination="mobileOtherDestination"
            :close="closeHamburger"
          />
        </div>
        <div class="mobile-overlay" :class="{openHamburger: mobileMenuShow}" @click="closeHamburger"></div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'

import HamburgerMenuButton from '~/components/utils/HamburgerMenuButton'
import Logo from '~/components/utils/Logo'
import CityList from '~/components/utils/CityList'
import CurrencyList from '~/components/utils/CurrencyList'
import HeaderCartButton from '~/components/utils/HeaderCartButton'
import MobileMenu from '~/components/utils/MobileMenu'
import { mapGetters } from 'vuex'

export default {
  name: 'Header',
  components: {
    HamburgerMenuButton,
    Logo,
    CityList,
    CurrencyList,
    HeaderCartButton,
    MobileMenu
  },
  computed: {
    ...mapGetters([
      'currency',
      'ribbonBanner'
    ])
  },
  data () {
    return {
      mobileMenuShow: false,
      menu: [
        // { name: 'my booking', router: '/booking-manage/' },
        { name: 'blog', router: '/blog/' },
        { name: 'about', router: '/about/' },
        { name: 'FAQ', router: '/faq/' },
      ],
      cities: [
        { name: 'Rome', router: '/rome-tours/', imgurl: '/img/cities/rome.jpg' },
        { name: 'Vatican', router: '/vatican-tours/', imgurl: '/img/cities/vatican.jpg' },
        { name: 'Venice', router: '/venice-tours/', imgurl: '/img/cities/venice.jpg' },
        { name: 'Florence', router: '/florence-tours/', imgurl: '/img/cities/florence.jpeg' },
        { name: 'Pompeii', router: '/pompeii-tours/', imgurl: '/img/cities/pompeii.jpeg' },
        { name: 'Naples', router: '/naples-tours/', imgurl: '/img/cities/naples.jpeg' },
        { name: 'Bologna', router: '/bologna-tours/', imgurl: '/img/cities/bologna.jpeg' },
        { name: 'Milan', router: '/milan-tours/', imgurl: '/img/cities/milan.jpeg' }
      ],
      mobileCities: [
        { name: 'Rome', router: '/rome-tours/', imgurl: '/img/cities/mobile/rome.jpg' },
        { name: 'Vatican', router: '/vatican-tours/', imgurl: '/img/cities/mobile/vatican.jpg' },
        { name: 'Venice', router: '/venice-tours/', imgurl: '/img/cities/mobile/venice.jpg' },
        { name: 'Florence', router: '/florence-tours/', imgurl: '/img/cities/mobile/florence.jpeg' },
        { name: 'Pompeii', router: '/pompeii-tours/', imgurl: '/img/cities/mobile/pompeii.jpeg' },
        { name: 'Naples', router: '/naples-tours/', imgurl: '/img/cities/mobile/naples.jpeg' },
        { name: 'Bologna', router: '/bologna-tours/', imgurl: '/img/cities/mobile/bologna.jpeg' },
        { name: 'Milan', router: '/milan-tours/', imgurl: '/img/cities/mobile/milan.jpeg' }
      ],
      franceCities: [
        { name: 'Paris', router: '/paris-tours/', imgurl: '/img/france-cities/paris.png' },
        { name: 'Bordeaux', router: '/bordeaux-tours/', imgurl: '/img/france-cities/bordeaux.png' },
      ],
      mobileFranceCities: [
        { name: 'Paris', router: '/paris-tours/', imgurl: '/img/france-cities/mobile/paris.png' },
        { name: 'Bordeaux', router: '/bordeaux-tours/', imgurl: '/img/france-cities/mobile/bordeaux.png' },
      ],
      otherDestination: [
        { name: 'Athens', router: 'https://www.takewalks.com/athens-tours/', imgurl: '/img/other-cities/athens.jpeg' },
        { name: 'London', router: 'https://www.takewalks.com/london-tours/', imgurl: '/img/other-cities/london.jpg' },
        { name: 'Barcelona', router: 'https://www.takewalks.com/barcelona-tours/', imgurl: '/img/other-cities/barcelona.jpeg' },
        { name: 'Madrid', router: 'https://www.takewalks.com/madrid-tours/', imgurl: '/img/other-cities/madrid.png' },
        { name: 'Seville', router: 'https://www.takewalks.com/seville-tours/', imgurl: '/img/other-cities/seville.jpg' },
        { name: 'Lisbon', router: 'https://www.takewalks.com/lisbon-tours/', imgurl: '/img/other-cities/Lisbon.png' },
        { name: 'Amsterdam', router: 'https://www.takewalks.com/amsterdam-tours/', imgurl: '/img/other-cities/amsterdam.png' },
        { name: 'Dublin', router: 'https://www.takewalks.com/dublin-tours/', imgurl: '/img/other-cities/dublin.png' },
        { name: 'Others', router: 'https://www.takewalks.com/', imgurl: '/img/other-cities/others.png' }
      ],
      mobileOtherDestination: [
        { name: 'Athens', router: 'https://www.takewalks.com/athens-tours/', imgurl: '/img/other-cities/mobile/athens.jpeg' },
        { name: 'London', router: 'https://www.takewalks.com/london-tours/', imgurl: '/img/other-cities/mobile/london.jpg' },
        { name: 'Barcelona', router: 'https://www.takewalks.com/barcelona-tours/', imgurl: '/img/other-cities/mobile/barcelona.jpeg' },
        { name: 'Madrid', router: 'https://www.takewalks.com/madrid-tours/', imgurl: '/img/other-cities/mobile/madrid.png' },
        { name: 'Seville', router: 'https://www.takewalks.com/seville-tours/', imgurl: '/img/other-cities/mobile/seville.jpg' },
        { name: 'Lisbon', router: 'https://www.takewalks.com/lisbon-tours/', imgurl: '/img/other-cities/mobile/Lisbon.png' },
        { name: 'Amsterdam', router: 'https://www.takewalks.com/amsterdam-tours/', imgurl: '/img/other-cities/mobile/amsterdam.png' },
        { name: 'Dublin', router: 'https://www.takewalks.com/dublin-tours/', imgurl: '/img/other-cities/mobile/dublin.png' },
        { name: 'Others', router: 'https://www.takewalks.com/', imgurl: '/img/other-cities/mobile/others.png' }
      ]
    }
  },
  mounted() {
    const _this = this;
    if (process.browser) {
      // guillaume scroll to top from footer links
      // get only 2 FIRST footer menu
      $('.footer-links .small > div:nth-child(-n + 2) a').on('click', function(){
          setTimeout(()=> {
          $('html, body').animate({
            scrollTop: 0
          }, 400)
          },0)
      })
    }
  },
  methods: {
    getCurrencyLabel () {
      if (this.currency === 'USD' || this.currency === 'CAD' || this.currency === 'AUD') {
        return '$ ' + this.currency
      } else if (this.currency === 'GBP') {
        return '£ ' + this.currency
      } else if (this.currency === 'EUR') {
        return '€ ' + this.currency
      }
    },
    isNotPaymentPage () {
      if (this.$route.path === '/payment/' || this.$route.path === '/confirm/') {
        return false
      } else {
        return true
      }
    },
    isConfirmationPage () {
      if (this.$route.path === '/confirm/') {
        return true
      } else {
        return false
      }
    },
    clickMegaMenu (name, link) {
      window.dataLayer.push({
        'event': 'clickMegaMenu',
        'click': {
          'clickText': name,
          'clickURL': 'www.walksofitaly.com' + link.substring(0, link.length - 1)
        }
      })
    },
    clickHome () {
      window.dataLayer.push({
        'event': 'clickMegaMenu',
        'click': {
          'clickText': 'Home',
          'clickURL': '/'
        }
      })
    },
    toggleNav () {
      $('#nav-container').show()
    },
    closeHamburger () {
      this.mobileMenuShow = false
      $('#wrapper').toggleClass('disable-scroll')
    },
    openHamburger () {
      this.mobileMenuShow = true
      $('#wrapper').toggleClass('disable-scroll')
    }
  },
  updated () {
    this.toggleNav()
  }
}
</script>
<style lang="scss" scoped>
@keyframes showElement {
  0% {
    display: none;
    right: -100%;
  }
  1% {
    display: block;
    right: -100%;
  }
  100% {
    display: block; 
    right: 0;
  }
}

#nav-container {
  .topnav-nav-small,
  .mobile-menu {
    display: none;
  }
  
  @include max-width($tablet) {
    .fixed-mobile {
      position: fixed;
      max-width: unset;
      padding: 0 8px !important;
    }
    .mobile-menu {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-end;
      column-gap: 16px;
      z-index: 998;
    }
    .topnav-cart-mobile {
      display: flex;
      position: relative;
    }

    // hamburger
    .mobile-menu-btn {
      display: none;
      text-align: center;
      color: $woi-red-main;
      @include max-width($tablet) {
        display: block;
      }
      div {
        padding: 6px;
        transition: background-color 200ms ease;
        border-radius: 50%;
      }
      &.active {
        div {
          background-color: $woi-gold-main;
        }
      }
    }

    .topnav-nav-small {
      display: none;
      height: 100vh;
      background: transparent;
      position: fixed;
      top: 0;
      right: -100%;
      
      &.openHamburger {
        display: block;
        right: 0;
        z-index: 100;
        animation: showElement 0.4s;
      }
    }
    .mobile-overlay {
      display: none;
      
      &.openHamburger {
        display: block;
        position: fixed;
        // z-index: -1;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        background: $overlay-bg;
        opacity: 0.8;
      }
    }
  }
  
}
</style>
