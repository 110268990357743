import Cookies from 'js-cookie'
import * as qs from 'querystring'
import {
  removeTrailingSlash,
  capitalize
} from '@/helpers/route'
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
export const gtmDatalayer = {
  methods: {
    gtmPushDatalayer (payload) {
      window.dataLayer.push(payload)
    },
    gtmFeaturesClick ({ category = 'Gallery', productName, productUrl }) {
      const obj = {
        event: 'Features',
        event_name: 'Features',
        event_category: `Page - Feature - ${category}`,
        event_action: `${productName} ${productUrl}`
      }

      this.gtmPushDatalayer(obj)
    },
    gtmPageView (pageView) {
      window.dataLayer.push({
        'event': 'customPageView',
        'pageView': pageView
      })
    },
    gtmPushLinkClick (payload) {
      const obj = {
        event: payload.event.trim(),
        click: {
          clickText: capitalize(payload.text),
          clickURL: removeTrailingSlash(payload.url)
        }
      }
      this.gtmPushDatalayer(obj)
    },
    /**
     * add query string for url so Tap Affiliate can track commission accross site (WOI -> TW)
     * @param {string} url 
     * @returns 
     */
    addQryString(url) {
      const queryObj = this.$route.query
      const qryStr = qs.stringify(queryObj)
      if (qryStr) {
        return `${url}?${qryStr}`
      }
      return url
    },
    getOneTrustSettings () {
      let otEnabled = true
      const otCookieConsent = (Cookies.get('OptanonConsent') || '').split('&').filter(Boolean)
      for (let i = 0; i < otCookieConsent.length; i++) {
        const eachOt = otCookieConsent[i].split('=')
        if (eachOt[0] === 'groups') {
          // console.log('ot 0 => ', eachOt)
          const groupArr = decodeURIComponent(eachOt[1]).split(',')
          for (let j = 0; j < groupArr.length; j++) {
            const eachOtInGroup = groupArr[j].split(':')
            if (eachOtInGroup[0] === 'C0002' && eachOtInGroup[1] === '1') {
              otEnabled = false
            }
          }
        }
      }
      // console.log('ot enabled => ', otEnabled)
      return otEnabled
    }
  }
}
