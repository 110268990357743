import Cookies from 'js-cookie'
import HomeController from '~/api/HomeController'

var homeController = new HomeController()

const Home = {
  state: {
    homepageData: {},
    bAllLoaded: false,
    heroImage: '',
    heroVideo: '',
    heroTitle: '',
    heroDescription: '',
    subHeading: '',
    tourGuides: [],
    featuredCities: [],
    sellingPointsTitle: '',
    sellingPoints: [],
    aboutUs: {},
    blogSection: {},
    recentlyViewed: [],
    inited: false,

    organizationMicroData: {},
    websiteMicroData: {},
    homepageMetaTitle: '',
    homepageMetaDescription: '',
    homepageFeaturedTours: {},
    bannerInfo: null
  },
  actions: {
    getNewHomepage: async(context) => {
      const data = await homeController.getNewHomepageData();
      context.commit('setHomeData', data)
    },
    addRecentlyViewed (context, tour) {
      context.commit('initRecentlyViewed')
      const formattedTour = {
        bookingTypeId: tour.bookingTypeId,
        image: tour.listingImage ?? tour.videoThumbnail,
        citySlug: tour.citySlug,
        slug: tour.tourSlug,
        shortTitle: tour.shortTitle,
        title: tour.title,
        priceMap: tour.priceMap,
        reviewStatus: {
          feedbackCount: tour.reviewsCount,
          feedbackAverage: tour.reviewsAverage,
        },
        timestamp: new Date()
      }
      const lst = context.getters.recentlyViewed
      const removeDupArr = lst.filter(t => t.bookingTypeId !== tour.bookingTypeId)
      const recentTwo = removeDupArr.slice(0, 2)
      const recentlyViewed = [formattedTour, ...recentTwo]
      context.commit('setRecentlyViewed', recentlyViewed)
      Cookies.set('RecentlyViewed', JSON.stringify(recentlyViewed))
    },

    getOrganizationMicroData: (context, payload) => {
      return new Promise((resolve, reject) => {
        homeController.getOrganizationMicroData(payload).then(data => {
          context.commit('setOrganizationMicroData', data)
          resolve()
        }, error => reject(error))
      })
    },
    getWebsiteMicroData: (context, payload) => {
      return new Promise((resolve, reject) => {
        homeController.getWebsiteMicroData(payload).then(data => {
          context.commit('setWebsiteMicroData', data)
          resolve()
        }, error => reject(error))
      })
    }
  },
  mutations: {
    setHomeData: (state, data) => {
      // console.log('data => ', data)
      state.homepageData = data
      state.heroTitle = data.heroTitle
      state.heroDescription = data.heroDescription


      state.featuredCities = data.homepageFeaturedCities
      state.sellingPointsTitle = data.sellingPointsTitle
      state.sellingPoints = data.homepageSellingPoints
      state.aboutUs = data.aboutUs
      state.blogSection = data.blogSection
      state.homepageFeaturedTours = data.homepageFeaturedTours
      state.bannerInfo = data.bannerInfo

      const { meta } = data
      state.homepageMetaTitle = meta?.pageMetaTitle
      state.homepageMetaDescription = meta?.pageMetaDescription
      state.heroImage = meta.pageMetaImage

      state.websiteMicroData = meta?.websiteMicroData
      state.organizationMicroData = meta?.organizationMicroData

    },
    setRecentlyViewed: (state, data) => {
      state.recentlyViewed = data
    },
    initRecentlyViewed: (state) => {
      if (state.inited) {
        return
      }
      const recentlyViewedStr = Cookies.get('RecentlyViewed')
      if (recentlyViewedStr) {
        const lst = JSON.parse(recentlyViewedStr)
        const now = new Date()
        const recentlyViewed = lst.filter((tour) => {
          const diff = (now - new Date(tour.timestamp))
          return Math.floor(diff / (1000 * 60 * 60 * 24)) <= 14
        })
        state.recentlyViewed = recentlyViewed
      }
      state.inited = true
    },
    setAllLoaded: (state, data) => {
      state.bAllLoaded = data
    },
    setOrganizationMicroData: (state, data) => {
      state.organizationMicroData = data
    },
    setWebsiteMicroData: (state, data) => {
      state.websiteMicroData = data
    },
    updateHomepageReviews: (state, reviewStatMap) => {
      const homepageFeaturedTours = state.homepageFeaturedTours
      if (homepageFeaturedTours.popularTours?.length > 0) {
        for (const popularTour of homepageFeaturedTours.popularTours) {
          const { bookingTypeId } = popularTour
          const reviewStat = reviewStatMap[bookingTypeId.toString()]
          // console.log('reviewStat => ', reviewStat)
          if (reviewStat) {
            popularTour.reviewStatus = {
              feedbackAverage: reviewStat.average,
              feedbackCount: reviewStat.totalReviews,
            }
            // test code
            // popularTour.reviewStatus = {
            //   feedbackAverage: 2,
            //   feedbackCount: 777,
            // }
          }
        }
      }
      if (homepageFeaturedTours.newTours?.length > 0) {
        for (const newTour of homepageFeaturedTours.newTours) {
          const { bookingTypeId } = newTour
          const reviewStat = reviewStatMap[bookingTypeId.toString()]
          // console.log('reviewStat => ', reviewStat)
          if (reviewStat) {
            newTour.reviewStatus = {
              feedbackAverage: reviewStat.average,
              feedbackCount: reviewStat.totalReviews,
            }
            // test code
            // newTour.reviewStatus = {
            //   feedbackAverage: 2,
            //   feedbackCount: 777,
            // }
          }
        }
      }

    }
  },
  getters: {
    homepageData: state => {
      return state.homepageData
    },
    recentlyViewed: (state) => {
      return state.recentlyViewed
    },
    sellingPointsTitle: state => {
      return state.sellingPointsTitle
    },
    aboutUs: state => {
      return state.aboutUs
    },
    heroTitle: state => {
      return state.heroTitle
    },
    organizationMicroData: state => {
      return state.organizationMicroData
    },
    websiteMicroData: state => {
      return state.websiteMicroData
    },
    heroDescription: state => {
      return state.heroDescription
    },
    heroImage: state => {
      return state.heroImage
    },
    tourGuides: state => {
      return state.tourGuides
    },
    subHeading: state => {
      return state.subHeading
    },
    featuredCities: state => {
      return state.featuredCities
    },
    sellingPoints: state => {
      return state.sellingPoints
    },
    blogSection: state => {
      return state.blogSection
    },

    bAllLoaded: state => {
      return state.bAllLoaded
    },
    homepageMetaTitle: state => {
      return state.homepageMetaTitle
    },
    homepageMetaDescription: state => {
      return state.homepageMetaDescription
    },
    homepageFeaturedTours: state => {
      return state.homepageFeaturedTours
    },
    bannerInfo: state => {
      return state.bannerInfo
    }
  }
}

export default Home
