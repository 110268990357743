<template>
  <div id="megaNav" class="topnav-tours" :data-country-toggler="continent">
    <a @click="scrollTop" class="link main-menu">
      {{ continent }}
      <img :data-src="ArrowIcon" class="lazyload" />
    </a>
    <div
      class="topnav-dropdown cities-container"
      :data-country-target="continent"
    >
      <div class="navs-wrapper">
        <div class="navs-inner container">
          <div class="navs-block ">
            <div class="nav-block-title">Tours in Italy</div>
            <div class="nav-cities left">
              <div
                class="city-link-container"
                v-for="(city, index) in cities"
                v-bind:index="'city' + index"
                v-bind:key="'city' + city.name"
              >
                <nuxt-link
                  :to="city.router"
                  @click.native="clickMegaMenu(city.name, city.router)"
                >
                  <MenuThumbnail
                    :label="city.name"
                    :img="city.imgurl"
                    :caps="true"
                  />
                </nuxt-link>
              </div>
            </div>
          </div>

          <div class="navs-block france">
            <div class="nav-block-title pc">Tours in France</div>
            <div class="nav-block-title sm">France</div>
            <div class="nav-cities france">
              <div
                class="city-link-container"
                v-for="(city, index) in franceCities"
                v-bind:index="'city' + index"
                v-bind:key="'city' + city.name"
              >
                <nuxt-link
                  :to="city.router"
                  @click.native="clickMegaMenu(city.name, city.router)"
                >
                  <MenuThumbnail
                    :label="city.name"
                    :img="city.imgurl"
                    :caps="true"
                  />
                </nuxt-link>
              </div>
            </div>
          </div>

          <div class="navs-block europe">
            <div class="nav-block-title">Tours across Europe</div>
            <a
              href="https://www.takewalks.com/"
              class="underscore"
              @click="
                clickMegaMenuTW('takewalks', 'https://www.takewalks.com/')
              "
            >
              Available to book on our worldwide website
            </a>
            <div class="other-city-list right">
              <a
                v-for="(destination, index) in otherDestination"
                v-bind:index="index"
                v-bind:key="destination.name"
                :href="addQryString(destination.router)"
                @click="clickMegaMenuTW(destination.name, destination.router)"
                target="_blank"
              >
                <MenuThumbnail
                  :label="destination.name"
                  :img="destination.imgurl"
                  :variant="'small'"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import MenuThumbnail from "~/components/utils/MenuThumbnail";
import ArrowIcon from "~/assets/svg/arrow-icon-down.svg";

export default {
  components: {
    MenuThumbnail
  },
  methods: {
    // toggleNav () {
    //   $('#megaNav').toggleClass('openNav')
    // },
    scrollTop() {
      $("html, body").animate({ scrollTop: 0 }, 400);
    },
    clickMegaMenu(name, link) {
      window.dataLayer.push({
        event: "clickMegaMenu",
        click: {
          clickText: name,
          clickURL: "www.walksofitaly.com" + link.substring(0, link.length - 1)
        }
      });
    },
    clickMegaMenuTW(name, link) {
      console.log(name, link);
      window.dataLayer.push({
        event: "clickMegaMenu",
        click: {
          clickText: name,
          clickURL: link.substring(0, link.length - 1).replace("https://", "")
        }
      });
    }
  },
  data() {
    return {
      ArrowIcon: ArrowIcon
    };
  },
  name: "CityList",
  props: {
    continent: {
      type: String,
      required: true
    },
    cities: {
      type: Array,
      required: true
    },
    franceCities: {
      type: Array,
      required: true
    },
    otherDestination: {
      type: Array,
      required: true
    },
    caps: {
      default: true,
      type: Boolean,
      required: false
    }
  }
};
</script>

<style lang="scss" scoped>
@keyframes showElement {
  0% {
    display: none;
    opacity: 0;
    transform: translateY(-50px);
  }
  1% {
    display: block;
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    display: block;
    opacity: 1;
    transform: translateY(0px);
  }
}

.topnav-tours {
  text-transform: uppercase;
  cursor: pointer;
  @extend %subtitle-2-bold;
  &:hover,
  &.openNav {
    // guillaume :: trigger dropdown animation
    // important
    .link.main-menu {
      color: #fff !important;
      background: $woi-gold-dark; // ++++++
      > img {
        transform: rotate(-180deg);
      }
    }
    .topnav-dropdown {
      &:hover {
        cursor: pointer;
      }
      display: block;
      opacity: 1;
      pointer-events: auto;
      z-index: 998;
      transform: translateY(0);
      animation: showElement 0.5s;
    }
  }
  .link {
    transition: all 0.4s ease;
    -webkit-transition: all 0.5s ease;
    padding: 10px 25px;
    color: $woi-red-main;
    font-weight: 400;
    letter-spacing: 0.5px;
    cursor: pointer;
    box-shadow: none;
    background: $woi-gold-main;
    display: flex;
    align-content: center;
    color: $white !important;
    @extend %subtitle-2-bold;
    > img {
      margin: 3px 0 0 15px;
      transition: all 0.4s ease-in-out 0s;
    }
  }
}

@include max-width($compact) {
  .topnav-dropdown .navs-block.france {
    padding: 0 10px !important;

    .nav-block-title.sm {
      display: block !important;
    }

    .nav-block-title.pc {
      display: none !important;
    }
  }
}

.topnav-dropdown {
  position: absolute;
  top: 68px;
  left: 0;
  background: transparent;
  padding-top: 40px;
  opacity: 0;
  pointer-events: none;
  z-index: 998;
  display: none;
  transform: translateY(-50px);
  &.cities-container {
    cursor: default !important;
    .city-link-container {
      width: 124px;
      height: 115px;
    }

    a {
      box-shadow: none;
    }
    a.underscore {
      text-transform: none;
      color: $woi-gold-main !important;
      @extend %description-2;
      font-size: 14px !important;
      text-decoration: underline !important;
    }
  }

  .navs-wrapper {
    width: 100vw;
    display: flex;
    border-top: 1px solid $woi-grey;
    padding: 20px;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.08);
    background-color: $white;
    // padding-left: 16%;
    justify-content: center;
  }

  .navs-inner {
    margin: 0;
    padding: 0;

    display: flex;
    justify-content: flex-start;
  }

  .navs-block {
    &.france {
      padding: 0px 32px;

      .nav-block-title.sm {
        display: none;
      }

      .nav-block-title.pc {
        display: block;
      }
    }

    &.europe {
      padding: 0px;
    }

    .nav-block-title {
      @extend %header-4;
      color: $woi-red-main;
      text-transform: none;
    }
    // background: $white;
    // width: 45%;
    .nav-cities.left {
      margin-top: 42px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      row-gap: 24px;
      column-gap: 18px;
      width: min-content;
    }

    .nav-cities.france {
      margin-top: 42px;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      row-gap: 24px;
      column-gap: 18px;
      width: min-content;
    }

    // width: 364px;
    .other-city-list.right {
      background: #f7f7f7;
      border-radius: 16px;
      padding: 12px 12px;
      padding-bottom: 2px;
      margin-top: 8px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 4px;
      column-gap: 8px;
      a {
        width: 90px;
      }
    }
  }

  // @extend %link-menu;
  &:hover {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  }
}
</style>
